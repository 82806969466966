var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.type
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.formIsValid,
      callback: function callback($$v) {
        _vm.formIsValid = $$v;
      },
      expression: "formIsValid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.newContract.contractType,
      callback: function callback($$v) {
        _vm.$set(_vm.newContract, "contractType", $$v);
      },
      expression: "newContract.contractType"
    }
  }, [_c('v-radio', {
    attrs: {
      "name": "newContract.isSelfEmployed",
      "label": "Selvstendig næringsdrivende",
      "value": 1
    }
  }), _c('v-radio', {
    attrs: {
      "name": "newContract.isSelfEmployed",
      "label": "Oppdragstaker",
      "value": 2
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "xs": "12"
    }
  }, [_vm.newContract.contractType === 1 ? _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.newContract.isPrimaryEmployer,
      callback: function callback($$v) {
        _vm.$set(_vm.newContract, "isPrimaryEmployer", $$v);
      },
      expression: "newContract.isPrimaryEmployer"
    }
  }, [_c('v-radio', {
    attrs: {
      "data-cy": "isPrimaryEmployerFalse",
      "name": "newContract.isPrimaryEmployer",
      "label": "Biinntekt",
      "value": false
    }
  }), _c('v-radio', {
    attrs: {
      "data-cy": "isPrimaryEmployerTrue",
      "name": "newContract.isPrimaryEmployer",
      "label": "Hovedinntekt",
      "value": true
    }
  })], 1) : _vm._e()], 1)], 1), _vm.newContract.contractType === 1 ? _c('v-row', [_c('v-col', {
    attrs: {
      "xs": "11"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "readonly": _vm.readonly,
      "rules": _vm.validateNotEmpty,
      "label": "Firma * ",
      "placeholder": "Start å skrive for å søke",
      "no-data-text": "Skriv minst 3 bokstaver for å søke",
      "items": _vm.organizationsList,
      "search-input": _vm.searchOrganization,
      "loading": _vm.isLoading,
      "item-text": _vm.itemText,
      "item-value": "id"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchOrganization = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchOrganization = $event;
      },
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.newContract.customerOrganizationId,
      callback: function callback($$v) {
        _vm.$set(_vm.newContract, "customerOrganizationId", $$v);
      },
      expression: "newContract.customerOrganizationId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "xs": "1"
    }
  }, [_vm.newContract.customerOrganizationId ? _c('v-icon', {
    staticStyle: {
      "margin-top": "25px"
    },
    on: {
      "click": function click($event) {
        _vm.newContract.customerOrganizationId = null;
      }
    }
  }, [_vm._v(" mdi-close ")]) : _vm._e()], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "xs": "12"
    }
  }, [_vm.newContract.contractType === 0 || _vm.newContract.contractType === 2 ? _c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "data-cy": "accountNo",
      "label": "Kontonummer",
      "rules": (_vm.validateNotEmpty, _vm.validateNonNegativeNumber)
    },
    model: {
      value: _vm.newContract.accountNo,
      callback: function callback($$v) {
        _vm.$set(_vm.newContract, "accountNo", $$v);
      },
      expression: "newContract.accountNo"
    }
  }) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "xs": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rows": "2",
      "data-cy": "note",
      "label": "Notat",
      "counter": "500",
      "rules": [_vm.validateMaxLength()],
      "required": ""
    },
    model: {
      value: _vm.newContract.note,
      callback: function callback($$v) {
        _vm.$set(_vm.newContract, "note", $$v);
      },
      expression: "newContract.note"
    }
  })], 1)], 1), _c('ContractLines', {
    attrs: {
      "contract": _vm.newContract,
      "type": _vm.type
    }
  }), _vm.showSignButton ? _c('v-btn', {
    staticClass: "sign-contract-button",
    on: {
      "click": _vm.signContract
    }
  }, [_vm._v("Signèr")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }