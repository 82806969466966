
import { api } from "@/api/api";
import { searchOrganizationsByName } from "@/api/contacts.api";
import { ApiCreateContractDto, ApiGetContractWithFileDto, ApiGetCustomerDto } from "@/api/generated/Api";
import ContractLines from "@/components/course/contract/ContractLines.vue";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { debounceHelper } from "@/shared/debounce";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import {
  validateBoolean,
  validateNonNegativeNumber,
  validateNotEmpty,
  validateMaxLength,
} from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref, Ref, watch } from "@vue/composition-api";
import Vue from "vue";

interface ContractDto extends ApiGetContractWithFileDto {
  contractType?: ContractType;
}

const initialNewContract: () => ContractDto = () => ({
  id: 0,
  courseId: 0,
  userId: 0,
  status: "",
  statusText: "",
  inserted: "",
  insertedBy: 0,
  updated: "",
  updatedBy: 0,
  accountNo: "",
  isSelfEmployed: false,
  customerOrganizationId: null,
  note: "",
  isPrimaryEmployer: false,
  taxMunicipalityNo: "",
  taxMunicipalityName: "",
  isActive: true,
  roleName: "Lærer",
  contractLines: [],
  isSigned: false,
  isStatusChangeable: false,
  isDeletable: false,
  isResendable: false,
  isMission: false,
  contractType: ContractType.Payroll,
});

enum ContractType {
  Payroll = 0,
  Freelance = 1,
  Mission = 2,
}

export default defineComponent({
  name: "ContractModal",
  components: { BaseModalForm, ContractLines },
  emits: ["refresh"],
  props: {
    userId: {
      type: Number,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    },
    contractId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();
    const formIsValid = ref(false);
    const isLoading = ref(false);
    const searchOrganization = ref<string>("");
    const organizationsList = ref();
    const newContract = ref(initialNewContract());
    const showSignButton = computed(() => props.type === ModalType.Edit && newContract.value.status === "not_sent");
    const readonly = computed(() => props.type === ModalType.Display);

    const itemText = (organization: ApiGetCustomerDto) => organization.customer.name;

    const signContract = async () => {
      if (!props.contractId) {
        return;
      }

      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          await api.economy.sendContractForSigningAsync(props.contractId);
          openNotification(store, NotificationItemType.Success, "Kontrakt sendt for signering");
        } catch (error) {
          openNotification(
            store,
            NotificationItemType.Error,
            "En feil oppsto ved innsending av kontrakt for signering"
          );
          return;
        } finally {
          context.emit("close");
        }
      });
    };

    const fetchExistingContract = async (contractId?: number) => {
      const existingContractId = contractId ? contractId : null;
      if (existingContractId === null) {
        return;
      }
      globalLoadingWrapper({ blocking: true }, async () => {
        newContract.value = (await api.economy.getContractAsync(existingContractId)).data;

        if (!newContract.value.isSelfEmployed && !newContract.value.isMission) {
          newContract.value.contractType = ContractType.Payroll;
        }

        if (newContract.value.isSelfEmployed && !newContract.value.isMission) {
          newContract.value.contractType = ContractType.Freelance;
        }

        if (!newContract.value.isSelfEmployed && newContract.value.isMission) {
          newContract.value.contractType = ContractType.Mission;
        }

        if (!newContract.value.isPrimaryEmployer && newContract.value.customerOrganizationId) {
          await populateExistingOrganization(newContract.value.customerOrganizationId, organizationsList);
        }
      });
    };

    const addNewContract = async () => {
      const submitContract: ApiCreateContractDto = newContract.value;
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          await api.economy.createContractAsync(props.courseId, props.userId, submitContract);
          openNotification(store, NotificationItemType.Success, "Kontrakt opprettet");
        } catch (error) {
          openNotification(store, NotificationItemType.Error, "En feil oppsto ved opprettelse av kontrakt");
        } finally {
          newContract.value = initialNewContract();
          context.emit("close");
          context.emit("refresh");
        }
      });
    };

    const updateContract = async () => {
      if (!props.contractId) {
        openNotification(store, NotificationItemType.Error, "En feil oppsto ved oppdatering av kontrakten");
        return;
      }
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          newContract.value = {
            ...newContract.value,
            contractLines: newContract.value.contractLines?.map((line) => ({
              ...line,
              unit: "timer",
            })),
          };

          await api.economy.updateContractAsync(props.contractId, newContract.value);
          openNotification(store, NotificationItemType.Success, "Kontrakt ble oppdatert");
        } catch (error) {
          openNotification(store, NotificationItemType.Error, "En feil oppsto ved oppdatering av kontrakten");
        } finally {
          newContract.value = initialNewContract();
          context.emit("close");
          context.emit("refresh");
        }
      });
    };

    const handleSubmit = async () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (!newContract.value.contractLines?.length) {
        openNotification(
          store,
          NotificationItemType.Error,
          "Kontraktslinje(r) må legges til før kontrakten kan lagres!"
        );
        return;
      }

      switch (newContract.value.contractType) {
        // payroll is no longer needed
        // case ContractType.Payroll:
        // newContract.value.isSelfEmployed = false;
        // newContract.value.isMission = false;
        // break;
        case ContractType.Freelance:
          newContract.value.isSelfEmployed = true;
          newContract.value.isMission = false;
          break;
        case ContractType.Mission:
          newContract.value.isSelfEmployed = false;
          newContract.value.isMission = true;
          break;
      }

      if (isValid && newContract.value.contractLines) {
        switch (props.type) {
          case ModalType.Add:
            await addNewContract();
            break;
          case ModalType.Edit:
            await updateContract();
            break;
          default:
            openNotification(store, NotificationItemType.Error, "Det skjedde en feil");
        }
      }
    };

    const populateExistingOrganization = async (id: number, list: Ref<ApiGetCustomerDto[]>) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const organization = await api.customer.getCustomerOrgByIdAsync(id);
        list.value = [organization.data];
      });
    };

    const updateOrganizationsList = async (searchValue: string) => {
      isLoading.value = true;
      const params: { name: string; pageSize: number } = {
        name: searchValue?.split(" ")[0],
        pageSize: 100,
      };
      try {
        await debounceHelper.debounce("updateOrganizationsList", 500);
        const response = await searchOrganizationsByName(params);
        if (response.data.length !== 0) {
          return response.data;
        }
        openNotification(store, NotificationItemType.Warning, `Fant ingen med fornavn: ${searchValue.split(" ")[0]} `);
      } catch (err) {
        openNotification(store, NotificationItemType.Error, "Det skjedde en feil");
      } finally {
        isLoading.value = false;
      }
    };

    watch(searchOrganization, () => {
      if (searchOrganization.value?.length < 3) {
        return;
      }
      updateOrganizationsList(searchOrganization.value).then((response) => (organizationsList.value = response));
    });

    onMounted(() => {
      fetchExistingContract(props.contractId);
    });

    const emitChanges = () => {
      context.emit("input", deepCloneObject(newContract.value));
    };

    return {
      formIsValid,
      newContract,
      readonly,
      handleSubmit,
      searchOrganization,
      organizationsList,
      isLoading,
      emitChanges,
      itemText,
      showSignButton,
      signContract,
      validateNotEmpty,
      validateBoolean,
      validateNonNegativeNumber,
      validateMaxLength,
    };
  },
});
